import { ResumeSocialEnum } from "../types/resume"

const resume = {
  base: {
    name: "Sean Shaifubahrim",
    contact: [
      {
        text: "Denver, CO",
        type: ResumeSocialEnum.LOCATION
      },
      {
        link: "mailto: hello@seanbhrim.com",
        text: "hello@seanbhrim.com",
        type: ResumeSocialEnum.EMAIL
      },
      {
        link: "tel: +17202203513",
        text: "+1 720-220-3513",
        type: ResumeSocialEnum.PHONE
      }
    ],
    socials: [
      {
        link: "https://github.com/seanbhrim",
        text: "GitHub",
        type: ResumeSocialEnum.GITHUB
      },
      {
        link: "https://www.linkedin.com/in/sean-sbhrim",
        text: "LinkedIn",
        type: ResumeSocialEnum.LINKEDIN
      },
      {
        link: "https://www.toptal.com/resume/sean-shaifubahrim",
        text: "Toptal",
        type: ResumeSocialEnum.TOPTAL
      }
    ]
  },
  experience: [
    {
      company: "Toptal",
      title: "Senior Fullstack Engineer",
      dateStart: "May 2022",
      dateEnd: "Present",
      location: "Remote, Freelance",
      bullets: [
        "Develop web/mobile applications using various modern frameworks and programming technologies.",
        "Architect highly scalable web solutions based on clients' business needs, budgets, and timelines.",
        "Lead development teams of varying sizes to deliver production ready code using common CI/CD workflows.",
        "Communicate development requirements and progress to stakeholders."
      ]
    },
    {
      company: "Modivcare",
      title: "Software Engineer II",
      altTitle: "Software Engineer 2",
      dateStart: "Aug 2021",
      dateEnd: "May 2022",
      location: "Denver, CO (USA)",
      bullets: [
        "Oversaw the design and fullstack development of Modivcare's React application. I conducted regular code reviews, worked with design to ensure WCAG and HIPAA regulation adherence, and justified designs and timelines to stakeholders.",
        "Collaborated with Modivcare's React Native team to maintain and develop their primary mobile application. My main responsibilities consisted of frontend development, code reviews, and design audits.",
        "Incepted and maintained Modivcare's React Native component library. This library would provide consistent components across multiple Modivcare applications while being robust enough for different business needs.",
        "Assist in maintaining the backend API which included coding Spring Boot AWS microservices."
      ]
    },
    {
      company: "Tension Climbing",
      title: "Software Engineer",
      dateStart: "Aug 2020",
      dateEnd: "May 2022",
      location: "Denver, CO (USA)",
      bullets: [
        "Led the project of developing and maintaining Tension Climbing's main mobile application using React Native and Firebase.",
        "Implemented the screens and algorithms designed by a team of climbing specialists for calculating a user's rock climbing performance.",
        "Managed the company's App Store Connect, Google Play Store, and Google Cloud accounts to oversee pre-production testing and production deployment.",
        "Monitored app health via Firebase Analytics."
      ]
    },
  ],
  skills: {
    backend: ["Node.js", "Express.js", "Spring"],
    cloud: ["AWS", "Google Cloud"],
    db: ["MongoDB", "RealmDB", "MySQL", "PostgreSQL"],
    frontend: ["React", "React Native", "Next.js", "WCAG", "UI/UX", "HTML5", "CSS3", "Sass", "Material UI", "Bootstrap"],
    language: ["Typescript", "Javascript", "Python", "Java"],
    misc: ["Git", "Agile", "Jest", "Figma", "Solutions Architecture"]
  },
  certs: [{
    name: "Web Accessibility Specialist(WAS)",
    org: {text: "IAAP", href: "https://www.accessibilityassociation.org"},
    period: "05/22 - 05/25",
    link: {text: "Credly Link", href: "https://www.credly.com/badges/738fbf31-aba4-4873-ba5b-95b0e22852df/public_url"}
  }],
  education: [
    {  }
  ]
}

export default resume
