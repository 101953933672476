import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppLayout from './layouts/AppLayout/AppLayout';
import ContactPage from './views/Contact/ContactPage';
import ErrorPage from './views/Error/ErrorPage';
import LandingPage from './views/Landing/LandingPage';
import ProjectsPage from './views/Projects/ProjectsPage';
import ResumePage from './views/Resume/ResumePage';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />} >
          <Route index element={<LandingPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='projects' element={<ProjectsPage />} />
          <Route path='resume' element={<ResumePage />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
