import { Col, Container, Row } from 'react-bootstrap'
import CoreValue from '../../components/landing/CoreValue/CoreValue'
import displaypic from '../../assets/images/icecaves_500.png'
import styles from './LandingPage.module.css'

const LandingPage = () => {
  return (
    <Container className={styles.landingPage} fluid>
      <Row className={styles.intro}>
        <span>
          <h1>Hi!</h1> My name is <strong>Sean Shaifubahrim</strong> and I'm a <strong>Full-Stack Developer</strong> that specializes in accessibility.
        </span>
      </Row>
      <Row>
        <Col xs={12} lg={7} className={styles.lpContent}>
          <h1>My solutions are:</h1>
          <CoreValue icon="speed" text="I will architect systems that can scale with your company." title="Scalable" />
          <CoreValue icon="devices" text="My layouts are designed for devices of all shapes and sizes." title="Responsive" />
          <CoreValue icon="accessibility_new" text="I can ensure Level AA WCAG compliance at every step of the way." title="Accessible" />
        </Col>
        <Col xs={12} lg={5} className={styles.lpContent}>
          <h1>Some things about me:</h1>
          <div className={styles.aboutme}>
            <img 
              alt='Sean rock climbing in the ice caves of Aspen, Colorado'
              src={displaypic}
            />
            <p>Currently based in <strong>Denver, CO</strong></p>
            <p>I'm a highly motivated engineer that values quality above all else. My passion is to make accessibility a staple in web and mobile development.</p>
            <p>My main stack includes <strong>React</strong>, <strong>React Native</strong>, and <strong>AWS</strong>.</p>
            <span>Still curious? <a href="resume">Learn more about me</a>.</span>
            <span>Ready to work together? <a href="contact">Let's talk</a>.</span>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default LandingPage
